<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card no-body>
          <!-- Spacer -->
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- name -->
                  <b-col
                    md="12"
                  >
                    <b-form-group
                      :label="$t('NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-name"
                          v-model="project.name"
                          maxlength="90"
                          :placeholder="$t('NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Referance ID -->
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      :label="$t('REFERENCE_ID')"
                      label-for="id-reference-number"
                    >
                      <b-form-input
                        id="id-reference-number"
                        v-model="project.reference_id"
                        maxlength="45"
                        :placeholder="$t('REFERENCE_ID')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- company -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="companyname"
                          :clearable="false"
                          class="flex-grow-1"
                          :options="companies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- strat date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_DATE')"
                      label-for="id-strat-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('START_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-strat-date"
                          v-model="project.start_date"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- end date -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('END_DATE')"
                      label-for="id-end-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('END_DATE')"
                        rules="required"
                      >
                        <flat-pickr
                          id="id-end-date"
                          v-model="project.end_date"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="project.description"
                        maxlength="300"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ project.ID ? $t("BUTTON.UPDATE") : $t("BUTTON.ADD") }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t("BUTTON.RESET") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { formatDate, avatarText } from '@core/utils/filter'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinList } from '@/constants/mixinValidations'
import { resolveUserRole } from '@/constants/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [mixinList],
  data() {
    return {
      project: {
        ID: 0,
        reference_id: null,
        name: '',
        start_date: null,
        end_date: null,
        company_id: 0,
        description: '',
      },
      company: '',
      companies: [],
      required,
      currentPage: 1,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your Project description',
    }
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      editorOption,
      formatDate,
      resolveUserRole,
      avatarText,
    }
  },
  async created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.project.ID = parseInt(router.currentRoute.params.id)
      if (this.project.ID !== 0) {
        this.fetchProjectByID(this.project.ID).then(async response => {
          if (response) {
            this.project = response.data
            await this.fetchCompany()
          }
        })
      }
    } else {
      await this.fetchCompany()
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('project', [
      'createProject',
      'updateProject',
      'fetchProjectByID',
      'fetchAllProjects',
    ]),
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
          if (this.project.ID) {
            this.company = this.companies.find(
              i => i.companyID === this.project.company_id,
            )
          } else if (this.companies.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.company = this.companies[0]
          }
        }
      })
    },
    clearPeriod() {
      if (this.project) {
        if (typeof this.project.start_date === 'string' && this.project.start_date === '') {
          this.project.start_date = null
        }
        if (typeof this.project.end_date === 'string' && this.project.end_date === '') {
          this.project.end_date = null
        }
      }
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          if (this.project.reference_id === '') {
            this.project.reference_id = null
          }
          this.clearPeriod()
          this.project.company_id = this.company.companyID
          if (this.project.ID === 0) {
            this.createProject(this.project)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.PROJECT_CREATED'))
                  this.$router.push({ name: 'project-list' })
                }
              }).catch(error => {
                if (error.response.data.status === 'failed') {
                  this.errorMessage(error.response.data.message.toString())
                }
              })
          } else {
            this.project = this.removeProperties(this.project, [
              'record_status',
              'version',
              'created_by',
              'created_timestamp',
              'modified_by',
              'modified_timestamp',
              'surveys',
              'competitions',
              'project_users'])
            this.updateProject(this.project)
              .then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.PROJECT_UPDATED'))
                  this.$router.push({ name: 'project-list' })
                }
              }).catch(error => {
                if (error.response.data.status === 'failed') {
                  this.errorMessage(error.response.data.message.toString())
                }
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
