var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"validationRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('NAME'),"label-for":"id-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id-name","maxlength":"90","placeholder":_vm.$t('NAME')},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('REFERENCE_ID'),"label-for":"id-reference-number"}},[_c('b-form-input',{attrs:{"id":"id-reference-number","maxlength":"45","placeholder":_vm.$t('REFERENCE_ID')},model:{value:(_vm.project.reference_id),callback:function ($$v) {_vm.$set(_vm.project, "reference_id", $$v)},expression:"project.reference_id"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY'),"label-for":"id-company"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"id":"id-company","placeholder":_vm.$t('SELECT_COMPANY'),"label":"companyname","clearable":false,"options":_vm.companies},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('START_DATE'),"label-for":"id-strat-date"}},[_c('validation-provider',{attrs:{"name":_vm.$t('START_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-strat-date"},model:{value:(_vm.project.start_date),callback:function ($$v) {_vm.$set(_vm.project, "start_date", $$v)},expression:"project.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('END_DATE'),"label-for":"id-end-date"}},[_c('validation-provider',{attrs:{"name":_vm.$t('END_DATE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"id-end-date"},model:{value:(_vm.project.end_date),callback:function ($$v) {_vm.$set(_vm.project, "end_date", $$v)},expression:"project.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DESCRIPTION'),"label-for":"id-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"id-description","maxlength":"300","options":_vm.editorOption},model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.project.ID ? _vm.$t("BUTTON.UPDATE") : _vm.$t("BUTTON.ADD"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTON.RESET"))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }